import React, {useEffect, useState} from 'react';
import "../styles/catalog.css";
import menuData from "../data/menu.json";
import { usePopupWithContent } from "./PopupWithContent";
import LogoBig from "../images/logo.webp";
import LogoMini from "../images/logo_mini.webp"
import CartIcon from "../images/icons/cart.svg"
import PlaceholderImage from "../images/logo_mini.webp";

interface MenuItem {
    name: string;
    description: string;
    price: number;
    image: string;
}

interface Category {
    category: string;
    items: MenuItem[];
    image: string;
}

interface CartItem extends MenuItem {
    quantity: number;
}

const Catalog: React.FC = () => {
    const [selectedCategory, setSelectedCategory] = useState<string>(menuData.menu[0]?.category || '');
    const [cartItems, setCartItems] = useState<CartItem[]>(() => {
        const savedCart = localStorage.getItem('cartItems');
        return savedCart ? JSON.parse(savedCart) : [];
    });
    const [cartSum, setCartSum] = useState(0);

    const { showMessageWithContent } = usePopupWithContent();
    const [loadedImages, setLoadedImages] = useState<string[]>([]);

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const productImageHandler = (heading: string, image: React.ReactNode) => {
        showMessageWithContent(heading, image);
    };

    const handleCategoryClick = (category: string) => {
        setSelectedCategory(category);
        catalogColumnHandler(1);
        const productsDiv = document.querySelector('.products');
        if (productsDiv) {
            productsDiv.classList.add('activeSection');
        }
        setLoadedImages([]);
    };

    const addToCart = (item: MenuItem) => {
        const existingItem = cartItems.find((cartItem) => cartItem.name === item.name);
        if (existingItem) {
            setCartItems(cartItems.map((cartItem) =>
                cartItem.name === item.name ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
            ));
        } else {
            setCartItems([...cartItems, { ...item, quantity: 1 }]);
        }
    };

    const removeFromCart = (name: string) => {
        setCartItems(cartItems.filter((item) => item.name !== name));
    };

    const decreaseQuantity = (name: string) => {
        const existingItem = cartItems.find((item) => item.name === name);
        if (existingItem && existingItem.quantity === 1) {
            removeFromCart(name);
        } else {
            setCartItems(cartItems.map((item) =>
                item.name === name ? { ...item, quantity: item.quantity - 1 } : item
            ));
        }
    };

    const increaseQuantity = (name: string) => {
        setCartItems(cartItems.map((item) =>
            item.name === name ? { ...item, quantity: item.quantity + 1 } : item
        ));
    };

    const handleImageLoad = (imageSrc: string) => {
        setLoadedImages((prevLoadedImages) => [...prevLoadedImages, imageSrc]);
    };

    const selectedCategoryItems = menuData.menu.find(
        (category: Category) => category.category === selectedCategory
    )?.items || [];

    const catalogColumnHandler = (index: number) => {
        console.log("catalog columnt handler ", index)
        const catalogDivs = document.querySelectorAll('.catalog > div');
        catalogDivs.forEach((div, idx) => {
            if (idx === index) {
                div.classList.add('activeSection');
            } else {
                div.classList.remove('activeSection');
            }
        });
    }

    const [theme, setTheme] = useState('theme-light');

    return (
        <div className={`catalog ${theme}`}>
            <div className="categs activeSection" onClick={() => catalogColumnHandler(0)}>
                <div className="products-header">
                    <div className={"logos"}>
                        <img src={LogoBig} className={"logoBig"}/>
                        <img src={LogoMini} className={"logoMini"}/>
                    </div>
                </div>
                <div className="categsList">
                    {menuData.menu.map((category: Category, index: number) => (
                        <div
                            className={`singleCategory ${selectedCategory === category.category ? 'activeCategory' : ''}`}
                            key={index}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleCategoryClick(category.category)
                            }}
                        >
                            <img src={category.image} alt={category.category}/>
                            {category.category}
                        </div>
                    ))}
                </div>
            </div>
            <div className="products " onClick={() => catalogColumnHandler(1)}>
                <div className="products-header">
                    <div className={"columnHeading"}>{selectedCategory || ""}</div>
                </div>
                <div className="products-list">
                    {selectedCategoryItems.map((item, idx) => (
                        <div key={idx} className="singleProduct">
                            <img
                                src={loadedImages.includes(item.image) ? item.image : PlaceholderImage}
                                alt={item.name}
                                onLoad={() => handleImageLoad(item.image)}
                                onClick={() => {
                                    productImageHandler(item.name, <img src={item.image} alt={item.name} />);
                                }}
                            />
                            <div className={"singleProductText"}>
                                <h3>{item.name}</h3>
                                <p className="singleProductDescription">{item.description}</p>
                                {/*<p className="singleProductPrice">{item.price} ₽</p>*/}
                                <button onClick={() => addToCart(item)}>В заказ</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="cart " onClick={() => catalogColumnHandler(2)}>
                <div className="products-header">
                    <img className="mobileCartIcon" src={CartIcon} />
                    <div className={"columnHeading"}>Корзина</div>
                </div>
                <div className="products-list">
                    {cartItems.length > 0 ?
                        cartItems.map((item, idx) => (
                            <div key={idx} className="cartItem">
                                <img src={item.image} alt={item.name}/>
                                <div className={"singleCartItemText"}>
                                    <h3>{item.name}</h3>
                                    {/*<p className="singleProductPrice">{item.price.toFixed(2)} ₽</p>*/}
                                    <div className="singleCartItemButtons">
                                        <div className="singleCartItemButtonsQty">
                                            <button onClick={() => decreaseQuantity(item.name)}>-</button>
                                            <p className="singleProductQty">{item.quantity}</p>
                                            <button onClick={() => increaseQuantity(item.name)}>+</button>
                                        </div>
                                        <button
                                            onClick={() => removeFromCart(item.name)}
                                            className="removeFromCartButton"
                                        >×</button>
                                    </div>
                                </div>

                            </div>
                        ))
                        :
                        <div className="emptyCart">Добавьте блюда к вашему заказу. Не забудьте напитки и соусы</div>
                    }
                </div>

            </div>
        </div>
    )
};

export default Catalog;
